import { createReducer, ActionType } from 'typesafe-actions';
import * as neptingActions from './nepting.actions';

export type NeptingActions = ActionType<typeof neptingActions>;

export enum NeptingPaymentStatus {
  PENDING,
  ERROR,
  ABORTED,
  NONE,
}

const defaultState: NeptingState = {
  status: NeptingPaymentStatus.NONE,
  errorMessage: null,
  isPayementSuccessful: null,
};

export type NeptingState = {
  status: NeptingPaymentStatus;
  errorMessage: string | null;
  ticket?: string;
  isPayementSuccessful: boolean | null;
};

const neptingReducer = createReducer<NeptingState, NeptingActions>(defaultState)
  .handleAction(
    neptingActions.neptingPaymentSequenceEnded,
    (state, action) => ({
      ...state,
      ticket: action.payload.ticket,
      isPayementSuccessful: action.payload.isSucess,
      errorMessage: null,
      status: NeptingPaymentStatus.NONE,
    }),
  )
  .handleAction(neptingActions.startNeptingPayment, state => {
    return {
      ...state,
      errorMessage: null,
      status: NeptingPaymentStatus.PENDING,
    };
  })
  .handleAction(neptingActions.displayNeptingErrorMessage, (state, action) => ({
    ...state,
    errorMessage: action.payload.errorMessage,
    status: NeptingPaymentStatus.ERROR,
  }));

export default neptingReducer;
